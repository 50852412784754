<script setup lang="ts">
import type { Course } from '@/models/course';
import { CourseType } from '@/models/courseTypeEnum';
import moment from 'moment';
import { onMounted, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useCourseStore } from '@/stores/courseStore';
import { CopyDocument, Search, Refresh, MessageBox, Message } from '@element-plus/icons-vue';
import type { TableColumnCtx } from 'element-plus';


const showHistory = ref(false);
const showMaster = ref(localStorage.getItem("listFilterMaster") == "1");
const search = ref("");
const { t } = useI18n();
const router = useRouter();

const courseStore = useCourseStore();

onMounted(async () => {
    courseStore.getCourses(showHistory.value);
});

const reload = async() =>{
  courseStore.$reset();
  await courseStore.getCourses(showHistory.value);
}
const edit = (row: Course) => {
    router.push({ name: 'courseEdit', params: { 'Id': row.id } });
}

const copy = (row: Course) => {
    router.push({ name: 'courseEdit', params: { 'Id': row.id }, query: { 'copy': '1' } });
}

const frmType = (row: any, column: any, t: number) => {
    return CourseType[t];
}
const frmDate = (row: CourseChild, column: TableColumnCtx<CourseChild>, t: Date) => {
    if(column.property == "date" && !row.hasCourseDate)
       return "";
    if(column.property == "date" && row.type == CourseType.Master)
       return "";
    return moment(t).format("DD/MM/YYYY")
}

interface CourseChild extends Course {
    hasChildren?: boolean;
    children?: Course[];
}
const filteredCourses = computed(() => {
    const x = showMaster.value ? courseStore.courses.filter(f => f.type == CourseType.Master) as CourseChild[] : courseStore.courses.filter(f => f.type != CourseType.Master) as CourseChild[];
    const r = x.map(u => { u.hasChildren = (u.masterCourses && u.masterCourses.length > 0); return u; });
    if(search.value)
        return r.filter(f=>filterFunction(f));
    return r;
});

const filterFunction = (o: Course) => {
    const x = o as any;
    return Object.keys(x).some(function(k) {
        if(x[k]){
            //console.log(JSON.stringify(x[k]));
            return JSON.stringify(x[k]).toLowerCase().indexOf(search.value.toLowerCase()) != -1;
        }
        return false;
    });
}

const changemaster = () => {
    localStorage.setItem("listFilterMaster", showMaster.value ? "1" : "0");
}
const load = (
    row: Course,
    treeNode: unknown,
    resolve: (date: Course[]) => void
) => {
    courseStore.getMasterCoursesObj(row).then(o => {
        resolve(o)
    });
}
</script>

<template>
    <el-card class="box-card w-100 m-20">
        <template #header>
            <div class="card-header">
                <div class="text-2xl left">{{ $t("course.list") }}</div>
                <div class="w-64 m-2">
                <el-input
                        v-model="search"
                        placeholder="Cerca"
                        :prefix-icon="Search"
                    /></div>
                <el-switch v-model="showMaster" class="mr-2" inline-prompt :active-text="t('course.showMaster')"
                    :inactive-text="t('course.noShowMaster')" size="large" @change="changemaster" />
                <el-switch v-model="showHistory" class="mr-2" inline-prompt :active-text="t('course.showHistory')"
                    :inactive-text="t('course.noShowHistory')" @change="courseStore.getCourses(showHistory)" size="large" />
                <el-button class="button" type="primary" @click="$router.push({ name: 'courseEdit', params: { 'Id': -1 } })">{{ $t("course.new")
                }}</el-button>
                <el-button class="button" @click="reload()" :icon="Refresh" round/>
            </div>
        </template>
        <el-table :data="filteredCourses" style="width: 100%" @row-click="edit" row-key="id"
            :tree-props="{ hasChildren: 'hasChildren', children: 'children' }" lazy :load="load">
            <el-table-column prop="name" label="Name" />
            <el-table-column prop="type" label="Type" :formatter="frmType" width="120"/>
            <el-table-column prop="date" label="Date" :formatter="frmDate" width="120"/>
            <el-table-column prop="visualizationDate" label="Vis. Date" :formatter="frmDate" />
            <el-table-column fixed="right" width="150">
                <template #default="scope">
                    <el-tooltip content="Send Mail"><el-button tag="router-link" :to="{ name: 'sendMail', params: { courseId: scope.row.id } }" :icon="Message" circle></el-button></el-tooltip>
                    <el-tooltip content="Show orders"><el-button tag="router-link" :to="{ name: 'orderList', query: { course: scope.row.id } }" :icon="MessageBox" circle></el-button></el-tooltip>
                    <el-button :icon="CopyDocument" circle @click="copy(scope.row)"></el-button>
                </template>
            </el-table-column>
        </el-table>
    </el-card>
</template>

<style>
.card-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.left {
    display: flex;
    margin-right: auto;
}
</style>