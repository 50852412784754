import { ref } from 'vue'
import { defineStore } from 'pinia'
import config from '@/config/config'
import axios from 'axios'
import type { PersonalContent, PersonalContentExt } from '@/models/personalContent'

export const useUserContentStore = defineStore('userContent', () => {
  const loaded = ref(false);
  const loadedAll = ref(false);

  const content = ref({
    companies: [],
    partecipants: []
  } as PersonalContent)

  const contentAll = ref([{
    companies: [],
    partecipants: [],
    User: undefined,
    userid: undefined
  }] as PersonalContentExt[])

  const load = async () => {
    if(!loaded.value){
      const res = await axios.get<PersonalContent>(config().BASEURL + config().USERCONTENT, { headers: { Authorization: 'Bearer ' + localStorage.getItem("loginToken") as string } })
      content.value = res.data
      loaded.value = true;
    }
  }

  const loadAll = async () => {
    if(!loadedAll.value){
      const res = await axios.get<PersonalContentExt[]>(config().BASEURL + config().USERCONTENTALL, { headers: { Authorization: 'Bearer ' + localStorage.getItem("loginToken") as string } })
      contentAll.value = res.data
      loadedAll.value = true;
    }
  }

  const save = async () => {
    const res = await axios.post<PersonalContent>(config().BASEURL + config().USERCONTENT, content.value, { headers: { Authorization: 'Bearer ' + localStorage.getItem("loginToken") as string } })
    content.value = res.data
  }

  return { load, loadAll, save, content, contentAll }
})
