import { ref } from 'vue'
import { defineStore } from 'pinia'
import config from '@/config/config';
import axios from 'axios';
import type { CourseCategory } from '@/models/courseCategory';

export const useCourseCategoryStore = defineStore('courseCategory', () => {

  const categories = ref([] as CourseCategory[])

  const load = async () =>{ 
    const res = await axios.get<CourseCategory[]>(config().BASEURL + config().CATEGORIES);
    categories.value = res.data;
  }

  const save = async (category: CourseCategory) =>{ 
    const res = await axios.post<CourseCategory>(config().BASEURL + config().CATEGORIES, category, { headers: { Authorization: 'Bearer ' + localStorage.getItem("loginToken") as string } });
    
    load();
    return res.data;
  }

  return { load, categories, save }
})
