import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { Token } from '@/models/token'
import jwt_decode from 'jwt-decode'
import moment from 'moment'
import config from '@/config/config'
import axios from 'axios'

export const useLoginStore = defineStore('login', () => {
  const isLoggedIn = ref(false)
  const isLoggedInAdmin = ref(false)
  const eMail = ref("")
  const name = ref("")
  const isAdminToolActive = ref(localStorage.getItem('isAdminToolActive') == "t");

  const updateToken = (token: string) => {
    try{
      if (!token) throw Error('Token null')

      localStorage.setItem('loginToken', token)
      const decoded = jwt_decode<Token>(token)
      if (decoded.role == 'Admin') isLoggedInAdmin.value = true
      eMail.value = decoded.Id;
      name.value = decoded.unique_name;
      isLoggedIn.value = !!decoded.Id
    } catch(e){
      console.error(e);
    }
  }

  const logout = () => {
    localStorage.removeItem('loginToken');
    isLoggedIn.value = false;
    isLoggedInAdmin.value = false;
    isAdminToolActive.value = false;
  }

  const toggleAdmintools = () => {
    isAdminToolActive.value = !isAdminToolActive.value;
    localStorage.setItem('isAdminToolActive', isAdminToolActive.value ? "t": "f");
  }

  const localToken = localStorage.getItem('loginToken');
  if(localToken)
    updateToken(localToken);

const getPwd = () => {
  return btoa(moment().format('YY-MM-DD'))
}

const sendPwdReset = async (mail: string, lang: string) => {
  return await axios.post<string>(config().BASEURL + config().PWDRESETURL + lang, {
    UserName: mail,
    Password: getPwd()
  })
}

  return { isLoggedIn, isLoggedInAdmin, isAdminToolActive, eMail, name, toggleAdmintools, updateToken, logout, sendPwdReset }
})
