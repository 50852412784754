<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useCourseCategoryStore } from '@/stores/categoryStore';
import type { CourseCategory } from '@/models/courseCategory';
import { LanguageEnum } from '@/models/languages';
import { ElMessage } from 'element-plus';
import { useI18n } from 'vue-i18n';
import axios from 'axios';

let newCategory = {
    id: "",
    title: {},
} as CourseCategory;

const store = useCourseCategoryStore();

const route = useRoute();
const router = useRouter();
const loaded = ref(false);
const languages = Object.keys(LanguageEnum).filter(k => isNaN(Number(k)));
const { t } = useI18n();

const courseCategory = ref(newCategory);

const save = () => {
    try {
        store.save(courseCategory.value);
        router.push({ name: 'categoryList' });
    } catch (e) {

        let msg = "";
        if (typeof e === "string") {
            msg = e;
        } else if (axios.isAxiosError(e)) {
            msg = e.response?.data;
            // axios does an error check for us!
        } else {
            msg = JSON.stringify(e);
        }
        ElMessage({
            message: "Error in save - " + msg,
            type: 'error',
        })
    }
};

onMounted(async () => {
    if (route.params.Id && route.params.Id != "-1") {
        if (store.categories.length == 0)
            await store.load();
        const x = store.categories.find(f => f.id == route.params.Id)
        if (x)
            courseCategory.value = x;
    }
    loaded.value = true;
});
</script>

<template>
    <el-card class="box-card w-100 m-20" v-if="loaded">
        <template #header>
            <div class="card-header">
                <div class="text-2xl left">{{ $t("category.edit.pagetitle") }}</div>
            </div>
        </template>
        <el-form :model="courseCategory" label-width="180px" label-position="left">
            <el-form-item :label="$t('category.edit.title')">
                <el-input v-for="item in languages" :key="item" v-model="courseCategory.title[item]"
                    :placeholder="t('menuEditor.menu.title')" class="m-1"><template #prepend><span class="w-5">{{ item
                    }}</span></template></el-input>
            </el-form-item>
            <div class="flex justify-end">
                <el-button type="primary" @click="save">{{ $t("menuEditor.save") }}</el-button>
            </div>
        </el-form>
    </el-card>
</template>

<style>
.card-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.left {
    display: flex;
    margin-right: auto;
}

.el-form--label-left .el-form-item__label {
    line-height: 16px;
    padding-top: 8px;
}
</style>