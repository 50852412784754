<script setup lang="ts">
import axios from 'axios';
import config from '@/config/config';
import { onMounted, ref } from 'vue';
import { LanguageEnum } from '@/models/languages';
import { useI18n } from 'vue-i18n';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import AttachmentModule from '../../quillAttachments';
import type { AttachmentOptionsInterface } from '../../quillAttachments/module';
import type { FileResponse } from '@/models/fileResponse';
import quillToolbar from './quillToolbar.vue';
import { Hide, View } from '@element-plus/icons-vue';
import ImageResize from 'quill-image-resize-vue';

const props = defineProps<{
    content: { 
        [id: string]: string; 
    },
    templateValues?: [{
        name: string,
        value: {[id: string]: string}}
    ],
}>();
defineEmits<{
    (e: 'update:content', value: { [id: string]: string; }): void
}>();

const { t } = useI18n();
const useEditor = ref(true);
const cont = ref({} as { [id: string]: string; });
const languages = Object.keys(LanguageEnum).filter(k => isNaN(Number(k)));
const editorLang = ref(t("key"));
const editorLangSwitch = ref(editorLang.value == languages[1]);
const enablePreview = ref(true);
const toolbarId = "Toolbar" + (Math.random()).toString().replace(".", "");

const QModules = [{
    name: 'attachment',
    module: AttachmentModule,
    options: {
        upload: async (file: File) => {
            const formData = new FormData();
            formData.append("file", file);

            var fileRet = await axios.post<FileResponse>(config().BASEURL + config().UPLOAD, formData, { headers: { Authorization: 'Bearer ' + localStorage.getItem("loginToken") as string } });
            return fileRet.data.url;
        },
        render: (node: HTMLElement, { file }) => {
            if (file.name.endsWith("pdf")) {
                var DOM_img = document.createElement("img");
                DOM_img.src = "/pdf-icon.png";
                node.appendChild(DOM_img);
            }
            node.appendChild(document.createTextNode(file.name));
            node.setAttribute("style", "display: flex;align-items:center;");
            return node
        },
        onFileUploaded: () => {
        }
    } as AttachmentOptionsInterface
},
{
    name: 'imageResize',
    module: ImageResize
}];

onMounted(async () => {
    cont.value = props.content;
    enablePreview.value = localStorage.getItem("editor-enablePreviewChange") == "t";
});

const langChange = () => {
    if (editorLang.value == languages[1])
        editorLang.value = languages[0];
    else
        editorLang.value = languages[1];
}
const enablePreviewChange = () => {
    localStorage.setItem("editor-enablePreviewChange", enablePreview.value ? "t": "f");
}
const useAddress = (addr: {[id: string]: string}) => {
    cont.value = addr;
}
</script>

<template>
    <div class="flex flex-col">
        <div class="wysiwyg-header flex">
            <el-select v-model="editorLang" class="mx-2" placeholder="Select" size="small" v-if="languages.length != 2">
                <el-option v-for="item in languages" :key="item" :label="item" :value="item" />
            </el-select>
            <el-switch class="mx-2" v-model="editorLangSwitch" inline-prompt @change="langChange" :active-text="languages[1]"
                :inactive-text="languages[0]" v-if="languages.length == 2" />
            <el-switch v-model="useEditor" class="mx-2" inline-prompt
                style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                :active-text="t('contentEditor.useEditor')" :inactive-text="t('contentEditor.notuseEditor')" />
            <el-button size="small" round @click="useAddress(tmp.value)" v-for="tmp in templateValues" :key="tmp.name" >{{ tmp.name }}</el-button>
            <el-switch class="mx-2 ml-auto" v-model="enablePreview" v-if="languages.length == 2"  :active-action-icon="View" :inactive-action-icon="Hide" @change="enablePreviewChange" />
        </div>
        <div v-for="la in languages" v-bind:key="la">
            <el-input v-model="cont[la]" @input="$emit('update:content', cont)" :rows="10" type="textarea"
                v-if="!useEditor && la == editorLang" />

            <QuillEditor v-if="useEditor && la == editorLang" contentType="html" theme="snow" :toolbar="'#' + toolbarId"
                v-model:content="cont[la]" @text-change="$emit('update:content', cont)" :modules="QModules">
                <template #toolbar>
                    <quillToolbar :id="toolbarId"></quillToolbar>
                </template>
            </QuillEditor>
        </div>
        <template v-for="la in languages" v-bind:key="la">
            <div v-html="cont[la]" v-if="enablePreview && useEditor && languages.length == 2 && la != editorLang"
                class="bg-gray-100 p-3 mt-3 rounded"></div>
        </template>
    </div>
</template>

<style scoped>

</style>