<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useUserContentStore } from '@/stores/userContentStore';
import { Edit, Select } from '@element-plus/icons-vue';
import type { RegisterFormPartecipant } from '@/models/registerFormPartecipant';
import axios from 'axios';
import type { PersonalContent } from '@/models/personalContent';
import config from '@/config/config';
import { ElMessage } from 'element-plus';

const { t } = useI18n();

const userContentStore = useUserContentStore();

onMounted(async () => {
    userContentStore.loadAll();
});

const editRows = ref<{ userId: string; partecipantmail: string; partecipantname: string; }[]>([{
    userId: '',
    partecipantmail: '',
    partecipantname: ''
}]);

const addEdit = (userId: string, partecipant: RegisterFormPartecipant & { name_edit: string }) => {
    editRows.value.push({ userId: userId, partecipantmail: partecipant.email, partecipantname: partecipant.name });
    partecipant.name_edit = partecipant.name;
}

const savePart = async (editRow: { userId: string; partecipantmail: string; partecipantname: string; } | undefined, value: string) => {
    if(!editRow)
        return ElMessage({
                    message: "EditRow not found",
                    type: 'error',
                });
                
    const u = userContentStore.contentAll.find(f => f.userid == editRow.userId);
    if (u) {
        const p = u.partecipants.find(f => f.email == editRow.partecipantmail && f.name == editRow.partecipantname);
        if (p) {
            p.name = value;
            const res = await axios.post<PersonalContent>(config().BASEURL + config().USERCONTENT, u, { 
                params: { userid: editRow.userId },
                headers: { Authorization: 'Bearer ' + localStorage.getItem("loginToken") as string } 
            });
            if (res.data) {
                ElMessage({
                    message: "Personalcontent saved",
                    type: 'success',
                });
            } else {
                ElMessage({
                    message: "Error saving personalcontent",
                    type: 'error',
                });
            }
        }
    }
}
</script>

<template>
    <el-card class="box-card w-100 m-20">
        <template #header>
            <div class="card-header">
                <div class="text-2xl left">{{ $t("userAdmin.partecipant.list") }}</div>
            </div>
        </template>
        <el-table :data="userContentStore.contentAll" style="width: 100%" :default-expand-all="true">
            <el-table-column type="expand" prop="partecipants">
                <template #default="props">
                    <el-table class="ml-12" :data="props.row.partecipants" style="width: 100%">
                        <el-table-column prop="name" :label="t('userAdmin.partecipant.name')">
                            <template #default="scope">
                                <span
                                    v-if="!editRows.find(f => f.partecipantname == scope.row.name && f.partecipantmail == scope.row.email && f.userId == props.row.user.email)">{{
                                        scope.row.name }}</span>
                                <span v-else>
                                    <el-input v-model="scope.row.name_edit" size="mini" />
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="email" :label="t('userAdmin.partecipant.email')" />
                        <el-table-column prop="fiscalCode" :label="t('userAdmin.partecipant.fiscalCode')" />
                        <el-table-column prop="category" :label="t('userAdmin.partecipant.category')" />
                        <el-table-column fixed="right">
                            <template #default="scope">
                                <el-button
                                    v-if="!editRows.find(f => f.partecipantname == scope.row.name && f.partecipantmail == scope.row.email && f.userId == props.row.user.email)"
                                    @click="addEdit(props.row.user.email, scope.row)" :icon="Edit" circle />
                                <el-button
                                    v-if="editRows.find(f => f.partecipantname == scope.row.name && f.partecipantmail == scope.row.email && f.userId == props.row.user.email)"
                                    type="primary" @click="savePart(editRows.find(f => f.partecipantname == scope.row.name && f.partecipantmail == scope.row.email && f.userId == props.row.user.email), scope.row.name_edit)" :icon="Select" circle />
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </el-table-column>
            <el-table-column prop="user.name" :label="t('userAdmin.partecipant.admin.username')" />
            <el-table-column prop="user.email" :label="t('userAdmin.partecipant.admin.useremail')" />
        </el-table>
    </el-card>
</template>

<style>
.card-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.left {
    display: flex;
    margin-right: auto;
}
</style>