<script setup lang="ts">
import { watch, ref, nextTick, onMounted, computed } from 'vue';
import { useCourseStore } from '@/stores/courseStore';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { ElInput, ElMessage } from 'element-plus';
import axios from 'axios';
import config from '@/config/config';
import type { RegisterFormPartecipant } from '@/models/registerFormPartecipant';

const { t } = useI18n();
const route = useRoute();
const courseStore = useCourseStore();
const subject = ref({} as { [key: string]: string });
const partecipants = ref([
] as { mail: string, lang: string }[])
const partecipantsMaster = ref([
] as { mail: string, lang: string }[])
const body = ref({} as { [key: string]: string });
const courseBody = ref("");
const inputValue = ref('')
const inputLangValue = ref('')
const inputVisible = ref(false)
const preview = ref(true);
const InputRef = ref<InstanceType<typeof ElInput>>()
const template = "&lt;[COURSE_BODY]&gt;";

onMounted(async () => {
    await load();
});

watch(() => route.params.courseId ?? '', async () => {
    await load();
});

watch(() => body.value, () => {
    console.log(body.value);
}, { deep: true });

const mailBody = computed(() => {
    return (body.value['IT'] ?? '').replace(template, courseBody.value);
})

const load = async () => {
    if (route.params.courseId) {
        courseStore.getCourse(route.params.courseId as string);

        try {
            const resPartecipants = await axios.get<RegisterFormPartecipant[]>(config().BASEURL + config().COURSEMAIL_PARTECIPANTS + route.params.courseId, {
                headers: { Authorization: ('Bearer ' + localStorage.getItem('loginToken')) as string }
            });
            if (resPartecipants?.data)
                partecipants.value = resPartecipants.data.map((p) => ({ mail: p.email, lang: "it" }));
        } catch (error) {
            console.log(error);
        }

        try {
            const resPartecipantsMaster = await axios.get<RegisterFormPartecipant[]>(config().BASEURL + config().COURSEMAIL_PARTECIPANTS + route.params.courseId, {
                params: { type: 'master' },
                headers: { Authorization: ('Bearer ' + localStorage.getItem('loginToken')) as string }
            });
            if (resPartecipantsMaster?.data)
                partecipantsMaster.value = resPartecipantsMaster.data.map((p) => ({ mail: p.email, lang: "it" }));
        } catch (error) {
            console.log(error);
        }

        const resBody = await axios.post<string>(config().BASEURL + config().COURSEMAIL_BODY + route.params.courseId, {}, {
            headers: { Authorization: ('Bearer ' + localStorage.getItem('loginToken')) as string }
        })
        if (resBody?.data)
            nextTick(() => {
                courseBody.value = resBody.data;
                body.value['IT'] = "<p></p>" + "<p>"+template+"</p>" + "<p></p>";
            });
    }
}

const sendMail = async () => {
    const to = partecipants.value.map(p => p.mail);
    const toMaster = partecipantsMaster.value.map(p => p.mail);

    if(!to.length && !toMaster.length){
        ElMessage({
            message: "No recipients!",
            type: 'error',
        })
        return;
    }
    if(!subject.value['IT'] || !mailBody.value){
        ElMessage({
            message: "Subject and body are required!",
            type: 'error',
        })
        return;
    }

    const resBody = await axios.post<string>(config().BASEURL + config().SENDMAIL + route.params.courseId, {
        subject: subject.value['IT'] ?? '',
        body: mailBody.value,
        to: [...new Set(to), ...new Set(toMaster)]
    }, {
            headers: { Authorization: ('Bearer ' + localStorage.getItem('loginToken')) as string }
        });

        if(resBody?.data){
            ElMessage({
                message: "Mail sent!",
                type: 'success',
            })
        }
}

const handleInputConfirm = () => {
    if (inputValue.value) {
        partecipants.value.push({ mail: inputValue.value, lang: inputLangValue.value });
    }
    inputVisible.value = false
    inputValue.value = ''
}
const showInput = (lang: string) => {
    inputLangValue.value = lang;
    inputVisible.value = true;
    nextTick(() => {
        InputRef.value!.input!.focus()
    })
}
const handleClose = (removedTag: { mail: string }) => {
    partecipants.value = partecipants.value.filter(tag => tag.mail !== removedTag.mail);
}
const handleCloseMaster = (removedTag: { mail: string }) => {
    partecipantsMaster.value = partecipantsMaster.value.filter(tag => tag.mail !== removedTag.mail);
}
</script>

<template>
    <!-- Projects grid -->
    <div class="w-full p-10">
        <h1 v-html="courseStore.course.title[$t('key')]" class="mb-5" />
        <div class="gap-2 bg-white p-2 m-1">
            <el-tag v-for="tag in partecipants" :key="tag.mail" closable type="info" @close="handleClose(tag)">
                {{ tag.mail }}
            </el-tag>
            <el-tag v-for="tag in partecipantsMaster" :key="tag.mail" closable type="primary" @close="handleCloseMaster(tag)">
                {{ tag.mail }}
            </el-tag>
            <div v-if="inputVisible">
                <el-input ref="InputRef" v-model="inputValue" class="w-20" size="small"
                    @keyup.enter="handleInputConfirm" @blur="handleInputConfirm" />
            </div>
            <div v-else>
                <el-button class="button-new-tag" size="small" @click="showInput('de')">
                    + NEW
                </el-button>
            </div>
        </div>
        <el-input v-model="subject['IT']" :placeholder="t('sendmail.subject')" class="m-1"><template #prepend><span
                    class="w-5">IT</span></template></el-input>
        <div class="bg-white m-1">
            <wysiwygEditor v-model:content="body" editorLang="IT" :languageList="['IT']"></wysiwygEditor>
        </div>
        <el-button @click="sendMail" type="primary" class="m-1 mb-10">{{ t('sendmail.send') }}</el-button>
        <br>
        <el-switch v-model="preview" /> Preview
        <div v-if="preview" class="bg-white p-5">
            <div v-html="mailBody" />
        </div>
    </div>
</template>