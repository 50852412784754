import { ref } from 'vue'
import { defineStore } from 'pinia'
import config from '@/config/config'
import axios from 'axios'
import type { HeroImage } from '@/models/heroImage'
import type { UploadUserFile } from 'element-plus'

export const useHeroStore = defineStore('hero', () => {
  const heros = ref([] as HeroImage[])

  const herosAsUserFile = ref([] as UploadUserFile[])

  const load = async (force: boolean = false) => {
    if (heros.value.length == 0 || force) {
      const res = await axios.get<HeroImage[]>(config().BASEURL + config().HEROIMAGE)
      heros.value = res.data
      herosAsUserFile.value = heros.value.map((m) => {
        const x: UploadUserFile = { name: m.id, url: m.url }
        return x
      })
    }
  }

  const deleteImage = async (id: string) => {
    const res = await axios.delete<HeroImage[]>(config().BASEURL + config().HEROIMAGE + '/' + id, {
      headers: { Authorization: ('Bearer ' + localStorage.getItem('loginToken')) as string }
    })
    heros.value = res.data
  }

  return { load, heros, deleteImage }
})
