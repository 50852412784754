import Quill from 'quill'

const QuillModule = Quill.import('core/module')

const generateId = () => {
  const name = 'QUILL_ATTACHMENT'
  const id = new Date().getTime()
  return `${name}_${id}`
}

class AttachmentModule extends QuillModule {
  quill: Quill
  options: AttachmentOptionsInterface
  range: any
  fileHolder: HTMLInputElement

  constructor(quill: Quill, options: AttachmentOptionsInterface) {
    super()
    this.quill = quill
    this.options = options
    this.range = null
    this.fileHolder = document.createElement('input')
    if (typeof this.options.upload !== 'function') {
      console.warn('[Missing config] upload function that returns a promise is required')
    }

    if (typeof this.options.render !== 'function') {
      console.warn('[Missing config] render function that returns a doom node is required')
    }

    this.quill.getModule('toolbar').addHandler('attachment', this.selectLocalImage.bind(this))
  }

  selectLocalImage() {
    this.range = this.quill.getSelection()
    this.fileHolder = document.createElement('input')
    this.fileHolder.setAttribute('type', 'file')
    this.fileHolder.setAttribute('accept', '*/*')
    this.fileHolder.onchange = this.fileChanged.bind(this)
    this.fileHolder.click()
  }

  fileChanged() {
    if (this.fileHolder.files && this.fileHolder.files.length > 0) {
      const file = this.fileHolder.files[0]
      const attachmentId = generateId()
      const fileReader = new FileReader()

      fileReader.addEventListener(
        'load',
        () => {
          const base64content = fileReader.result
          this.insertAttachment(base64content, file, attachmentId)
        },
        false
      )

      if (file) {
        fileReader.readAsDataURL(file)
      }

      this.options
        .upload(file)
        .then((url) => {

          this.updateAttachment(attachmentId, url)
        })
        .catch((error) => {
          console.warn(error.message)
        })
    }
  }

  insertAttachment(dataUrl: string | ArrayBuffer | null, file: File, id: String) {
    this.quill.insertEmbed(this.range.index, 'attachment', {
      id,
      properties: { dataUrl, file },
      render: this.options.render
    })
  }

  updateAttachment(id: string, url: string) {
    const element = document.getElementById(id)
    if (element) {
      element.setAttribute('href', url)
      element.removeAttribute('id')
      if (typeof this.options.onFileUploaded === 'function') {
        this.options.onFileUploaded(element, url)
      }
    }
  }
}

export interface AttachmentOptionsInterface {
  upload(file: File): Promise<string>
  render(node: HTMLElement, filetype: { file: File }): HTMLElement
  onFileUploaded(elem: HTMLElement, url: string): void
}

export default AttachmentModule
