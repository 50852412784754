<script setup lang="ts">
import axios, { AxiosError } from 'axios';
import config from '@/config/config';
import { ref, onMounted } from 'vue';
import type { Content } from '@/models/content';
import { useRoute } from 'vue-router'
import { LanguageEnum } from '@/models/languages';
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const { t } = useI18n();
const content = ref({} as Content);
const actualContent = ref("");
const router = useRoute();
const languages = Object.keys(LanguageEnum).filter(k => isNaN(Number(k)));

const getContent = async (m: string) => {
    try {
        const res = await axios.get<Content>(config().BASEURL + config().CONTENT + m, { headers: { Authorization: 'Bearer ' + localStorage.getItem("loginToken") as string } });
        content.value = res.data;
    } catch (error) {
        const ex = error as AxiosError<unknown>;
        if (ex) {
            if (ex.response?.status == 404) {
                content.value = {
                    id: m,
                    body: {},
                    title: {},
                } as Content;
            }
        }
    }
}

const saveMenu = async () => {
    const res = await axios.post<Content>(config().BASEURL + config().CONTENT + actualContent.value, content.value, { headers: { Authorization: 'Bearer ' + localStorage.getItem("loginToken") as string } });
    content.value = res.data;

    ElNotification({
        title: t('Saved'),
        type: 'success'
    });
}

onMounted(async () => {
    actualContent.value = router.params.content as string;
    await getContent(actualContent.value);
});

</script>

<template>
    <el-card class="box-card w-100 m-20" v-if="content && content.title">
        <template #header>
            <div class="card-header">
                <div class="text-2xl">Content {{ actualContent }}</div>
            </div>
        </template>
        <div class="m-5">
            <span class="ml-3 inline-flex">{{ $t('contentEditor.content.title') }}</span>
            <el-input v-for="item in languages" :key="item" v-model="content.title[item]"
                :placeholder="t('menuEditor.menu.title')" class="m-1"><template #prepend><span class="w-5">{{ item
                }}</span></template></el-input>
        </div>
        <div class="m-5">
            <wysiwygEditor v-model:content="content.body" :title="$t('contentEditor.content.body')"></wysiwygEditor>
        </div>
        <div class="flex justify-end">
            <el-button type="primary" @click="saveMenu">{{ $t("menuEditor.save") }}</el-button>
        </div>
    </el-card>
</template>

