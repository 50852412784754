<script setup lang="ts">
import { onMounted, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useUserContentOrdersStore } from '@/stores/userContentOrdersStore';
import { PaymentType } from '@/models/PaymentType';
import { Search, Key, Delete } from '@element-plus/icons-vue';
import type { CartOrder } from '@/models/cartOrder';
import moment from 'moment';
import { useRoute } from 'vue-router';
import config from '@/config/config';
import axios from 'axios';
import { ElMessage } from 'element-plus';


const { t } = useI18n();
const route = useRoute();
const search = ref("");
const orderId = ref("");
const courseId = ref("");

const userContentOrdersStore = useUserContentOrdersStore();

onMounted(async () => {
    userContentOrdersStore.load();
});

watch(orderId, () => {
    userContentOrdersStore.load(orderId.value, courseId.value);
});

watch(search, () => {
    userContentOrdersStore.load(undefined, undefined, search.value);
});

watch(courseId, () => {
    userContentOrdersStore.load(orderId.value, courseId.value);
});

watch(() => route.query.course ?? '', async () => {
    courseId.value = route.query.course as string;
}, { immediate: true });

const del = async (o: CartOrder) => {
    const res = await axios.delete(config().BASEURL + config().DELORDER + (o.userId ?? '') + '/' + o.header.orderId, {
        headers: { Authorization: ('Bearer ' + localStorage.getItem('loginToken')) as string }
    });
    if (res.status == 200){
        await userContentOrdersStore.load(undefined, undefined, undefined, 20, true);
    }
    else
        ElMessage({
            message: 'Error in delete',
            type: 'error',
        });
}

</script>

<template>
    <el-card class="box-card w-100 m-20">
        <template #header>
            <div class="card-header">
                <div class="text-2xl left">{{ $t("userAdmin.orders.list") }}</div>
                <div class="w-64 m-2">
                    <el-input v-model="courseId" :placeholder="$t('userAdmin.orders.courseId')" clearable
                        :prefix-icon="Key" />
                </div>
                <div class="w-64 m-2">
                    <el-input v-model="orderId" :placeholder="$t('userAdmin.orders.searchId')" clearable
                        :prefix-icon="Key" />
                </div>
                <div class="w-64 m-2">
                    <el-input v-model="search" placeholder="Cerca" :prefix-icon="Search" />
                </div>
            </div>
        </template>
        <el-table :data="userContentOrdersStore.orders" :default-sort="{ prop: 'header.orderDate', order: 'descending' }"
            style="width: 100%">
            <el-table-column type="expand">
                <template #default="props">
                    <div class="ml-72">
                        <h3>{{ $t("userAdmin.orders.courses") }}</h3>
                        <el-table :data="props.row.items">
                            <el-table-column :label="t('userAdmin.orders.course')" prop="course.title">
                                <template #default="scope">
                                    {{ scope.row.course.title[$t("key")] }}
                                </template>
                            </el-table-column>
                            <el-table-column :label="t('userAdmin.orders.price')" prop="price.price" />
                            <el-table-column :label="t('userAdmin.orders.partecipant')" prop="partecipant.name">
                                <template #default="scope">
                                    {{ scope.row.partecipant.name }}<br>
                                    <a :href="'mailto:' + scope.row.partecipant.email" target="_blank">{{
                                        scope.row.partecipant.email }}</a>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="header.orderId" :label="t('userAdmin.orders.id')" />
            <el-table-column prop="header.orderDate" :label="t('userAdmin.orders.date')">
                <template #default="scope">
                    {{ moment(scope.row.header.orderDate).format("DD/MM/YYYY") }}
                </template></el-table-column>
            <el-table-column prop="header.company" :label="t('userAdmin.orders.company')">
                <template #default="scope">
                    {{ scope.row.header.company?.name }} <br />
                    {{ scope.row.header.company?.address }} <br />
                    {{ scope.row.header.company?.zip }} {{ scope.row.header.company.city }} <br />
                    {{ scope.row.header.company?.piva }}
                </template>
            </el-table-column>
            <el-table-column prop="header.paymentType" :label="t('userAdmin.orders.paymentType')">
                <template #default="props">
                    {{ PaymentType[props.row.header.paymentType] }}
                </template>
            </el-table-column>
            <el-table-column fixed="right" width="150">
                <template #default="scope">
                    <el-popconfirm title="Sicuro che vuoi cancellare?" @confirm="del(scope.row)">
                        <template #reference>
                            <el-button :icon="Delete" type="danger" circle></el-button>
                        </template>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-button v-if="userContentOrdersStore.hasHext" class="button" text @click="userContentOrdersStore.next()">{{
            $t("userAdmin.orders.next") }}</el-button>
    </el-card>
</template>

<style>
.card-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.left {
    display: flex;
    margin-right: auto;
}
</style>