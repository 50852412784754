<script setup lang="ts">
import { useHeroStore } from '@/stores/heroStore';
import { Plus } from '@element-plus/icons-vue'
import config from '@/config/config';
import { onMounted, ref } from 'vue';
import type { UploadProps } from 'element-plus'

const store = useHeroStore();
const headers = { Authorization: 'Bearer ' + localStorage.getItem("loginToken") as string };
const dialogImageUrl = ref('')
const dialogVisible = ref(false)

onMounted(() => {
    store.load();
});
const reload = () => {
    store.load(true);
};

const deleteFile = (uploadFile: any) => { console.log("uploadFile", uploadFile); store.deleteImage(uploadFile.id) };
const handlePictureCardPreview: UploadProps['onPreview'] = (uploadFile) => {
    dialogImageUrl.value = uploadFile.url!
    dialogVisible.value = true
}
</script>

<template>
    <el-upload class="w-100 m-20" v-model:file-list="store.heros" :action="config().BASEURL + config().HEROIMAGE"
        :on-success="reload" :on-remove="deleteFile" :on-preview="handlePictureCardPreview" :headers="headers"
        list-type="picture-card">
        <el-icon>
            <Plus />
        </el-icon>
    </el-upload>
    <el-dialog v-model="dialogVisible">
        <img :src="dialogImageUrl" alt="Preview Image" />
    </el-dialog>
</template>