const config = {
    BASEURL: "https://api.koine-bz.org/",
    LOGIN: "Security/Login",
    UPDATEUSER: "Security/SaveUser",
    REGISTER: "Security/RegisterUser/",
    CHECKMAIL: "Security/EmailCheck/",
    ADMINUSERSURL: "Security/GetUsers",
    ADMINUSERURL: "Security/User/",
    PWDRESETURL: "Security/RestPasswordByAdmin/",
    MENU: "Content/Menu/",
    CONTENT: "Content/",
    USERCONTENT: "UserContent",
    USERCONTENTALL: "personalContents",
    HEROIMAGE: "Course/heroImage",
    TEACHER: "Course/teacher",
    CATEGORIES: "Course/category",
    COURSE: "Course/",
    COURSEByIds: "Course/ids",
    UPLOAD: "Attachment/upload",
    SETPASSWORDURL: "Security/SetPassword/",
    SAVEORDER: "UserContent/order/",
    GETORDERS: "UserContent/orders",
    DELORDER: "UserContent/order/",
    COURSEMAIL_PARTECIPANTS: "UserContent/CourseMail/partecipants/",
    COURSEMAIL_BODY: "UserContent/CourseMail/getBody/",
    SENDMAIL: "UserContent/sendCourseMail/"
}


const getConf = () => {
    if(window.location.host.startsWith("localhost"))
        config.BASEURL = "https://api-dev.koine-bz.org/";
    if(window.location.host.startsWith("dev.koine-bz.org"))
        config.BASEURL = "https://api-dev.koine-bz.org/";
    return config;
}

export default getConf;