import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import config from '@/config/config'
import axios from 'axios'
import type { Course } from '@/models/course'
import moment from 'moment'
import type { CoursePrice } from '@/models/coursePrice'
import { CourseType } from '@/models/courseTypeEnum'

export const useCourseStore = defineStore('courses', () => {
  const newCourse = {
    type: CourseType.Single,
    address: {},
    footerText: {},
    annotations: {},
    content: {},
    coursePrices: [] as CoursePrice[],
    textForSubscribers: {},
    timeMorning: [] as Date[],
    timeAfternoon: [] as Date[],
    title: {},
    tags: [] as string[],
    name: '',
    visualizationDate: new Date(),
    isOnlineCourse: true,
    isVisible: true,
    masterCourses: [],
    maxPartecipants: 0,
    credits: "",
    needsFiscalCode: true
  } as Course

  const cacheCourses = ref([] as Course[])

  const courses = ref([] as Course[])
  const course = ref(newCourse as Course)
  let maxDate: Date = new Date(2100, 1, 1)

  function $reset() {
    cacheCourses.value = [] as Course[];
    courses.value = [] as Course[]; 
    course.value = newCourse as Course;
    maxDate = new Date(2100,1,1);
  }

  const getMasterCoursesObj = async (course: Course) => {
    const x = await Promise.all(
      course.masterCourses.map(
        async (m) => cacheCourses.value.find((f) => f.id == m) ?? (await getCourseFromBackend(m))
      )
    )

    return x
  }

  const getParentCoursesObj = async (course: Course) => {
    if (!course.parentCourses) return []
    const x = await Promise.all(
      course.parentCourses.map(
        async (m) => cacheCourses.value.find((f) => f.id == m) ?? (await getCourseFromBackend(m))
      )
    )
    
    for(let i=0; i<x.length; i++)
      x[i].countCourses = await countCourses(x[i]);

    return x;
  }

  const countCourses = async (pr: Course) => {
    let count = 0;
    const mastercourses = await getMasterCoursesObj(pr);
    count = count + mastercourses.filter(m => m.type == CourseType.Single).length;

    const x = mastercourses.filter(m => m.type == CourseType.Master);
    for(let i=0;i<x.length;i++)
        count = count + x[i].masterCourses.length;
       
    return count;
}


  const getCourse = async (id: string) => {
    if (id == '-1') {
      course.value = newCourse
    } else {
      const c = courses.value.find((f) => f.id == id)
      if (c) course.value = c
      else {
        course.value = await getCourseFromBackend(id)
      }
      await getCourses(false, course.value.visualizationDate)
      if (course.value.masterCourses){
        for(const mc of course.value.masterCourses)
        {
          const found = courses.value.find((f) => f.id == mc);
          if (!found) {
            const c = await getCourseFromBackend(mc);
            courses.value.push(c);
          }
        }
      }
    }
  }

  const getCourseFromBackend = async (id: string) => {
    const res = await axios.get<Course>(config().BASEURL + config().COURSE + id, {
      headers: { Authorization: ('Bearer ' + localStorage.getItem('loginToken')) as string }
    })
    return res.data
  }

  const getCourses = async (showHistory: boolean, frmDate?: Date) => {
    if(!frmDate)
       frmDate = addDays(new Date(), -15);
    const fromDate: Date = showHistory ? new Date(1900, 1, 1) : frmDate
    if (fromDate < maxDate) {
      const res = await axios.get<Course[]>(
        config().BASEURL + config().COURSE + moment(fromDate).format('YYYYMMDD') + '/500/0',
        { headers: { Authorization: ('Bearer ' + localStorage.getItem('loginToken')) as string } }
      )
      cacheCourses.value = res.data
      courses.value = cacheCourses.value
      maxDate = fromDate
    } else {
      courses.value = cacheCourses.value.filter((f) => moment(f.visualizationDate) >= moment(fromDate))
    }
  }

  const allSingleCourses = computed(() => {
    return courses.value
      .filter((f) => f.type == CourseType.Single)
      .map((c) => {
        return {
          label: c.name,
          key: c.id
        }
      })
  })

  const addDays = (from: Date, days: number) => {
    const date = new Date(from.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  const save = async (c: Course) => {
    // Delete the objects
    const pc = JSON.parse(JSON.stringify(c)) as Course
    pc.trainer = undefined
    pc.trainer2 = undefined

    const res = await axios.post<Course>(config().BASEURL + config().COURSE, pc, {
      headers: { Authorization: ('Bearer ' + localStorage.getItem('loginToken')) as string }
    })
    const co = courses.value.find((f) => f.id == res.data.id)
    if (co) courses.value[courses.value.indexOf(co)] = res.data
    else courses.value.push(res.data)

    const cox = cacheCourses.value.find((f) => f.id == res.data.id)
    if (cox) cacheCourses.value[cacheCourses.value.indexOf(cox)] = res.data
    else cacheCourses.value.push(res.data)
    // Fix child courses
    return res.data
  }

  const deleteCourse = async (c: Course) => {
    const res = await axios.delete<Course>(config().BASEURL + config().COURSE + c.id, {
      headers: { Authorization: ('Bearer ' + localStorage.getItem('loginToken')) as string }
    })
    courses.value = courses.value.filter((f) => f.id != c.id)
    cacheCourses.value = cacheCourses.value.filter((f) => f.id != c.id)
    return res.data
  }

  return {
    getCourses,
    save,
    getCourse,
    courses,
    course,
    newCourse,
    allSingleCourses,
    deleteCourse,
    getMasterCoursesObj,
    getParentCoursesObj,
    $reset
  }
})
