<script setup lang="ts">
import { LanguageEnum } from '@/models/languages';
import { isAxiosError } from 'axios';
import { onMounted, ref, computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import VueTagsInput from '@sipec/vue3-tags-input';
import { CourseType } from '@/models/courseTypeEnum';
import { useTrainerStore } from '@/stores/teacherStore';
import { useCourseCategoryStore } from '@/stores/categoryStore';
import { useHeroStore } from '@/stores/heroStore';
import { Delete, Plus, Edit, Check } from '@element-plus/icons-vue';
import { ElMessage } from 'element-plus';
import { useCourseStore } from '@/stores/courseStore';
import { storeToRefs } from 'pinia';
import type { FormInstance, FormRules } from 'element-plus';
import type { Course } from '@/models/course';
import type { InternalRuleItem, SyncValidateResult } from 'async-validator';

interface Itag {
    text: string,
    tiClasses: string[]
}

const trainerStore = useTrainerStore();
const courseStore = useCourseStore();
const heroStore = useHeroStore();
const categoryStore = useCourseCategoryStore();

const { t } = useI18n();
const languages = Object.keys(LanguageEnum).filter(k => isNaN(Number(k)));
const route = useRoute();
const router = useRouter();
const loaded = ref(false);
const tags = ref([] as Itag[]);
const tag = ref("");
const priceEditId = ref(-1)
const { course } = storeToRefs(courseStore);
const ctypes = Object.keys(CourseType).filter(k => !isNaN(Number(k))).map(v => { return { "key": Number(v), "value": CourseType[Number(v)] } });

onMounted(async () => {
    await courseStore.getCourse(route.params.Id as string);

    if (course.value.tags == null) course.value.tags = [] as string[];

    tags.value = course.value.tags.map((t) => {
        return { text: t, tiClasses: ['ti-valid'] }
    })
    if(course.value.footerText == null) course.value.footerText = {};

    LoadCoursesForMaster(course.value.masterVisualizationDate ?? new Date());
    trainerStore.load();
    heroStore.load();
    categoryStore.load();
    loaded.value = true;

    if (route.query.copy == "1") {
        course.value = JSON.parse(JSON.stringify(course.value));
        course.value.id = undefined
    }
});

const LoadCoursesForMaster = async (from: Date) => {
    await courseStore.getCourses(false, addDays(from, -30));
}

const addDays = (from: Date, days: number) => {
    const date = new Date(from.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

const allTransferCourses = computed(() => {
    return courseStore.courses
        .filter(f => f.id != course.value.id)
        .map((c) => {
            return {
                label: c.name + (c.type == CourseType.Master ? " (Master)" : " (Single)"),
                key: c.id
            }
        })
})

const changeTrainer = () => {
    course.value.trainer = trainerStore.trainers.find(f => f.id == course.value.trainerId) ?? { id: "" };
}

const changeTrainer2 = () => {
    course.value.trainer2 = trainerStore.trainers.find(f => f.id == course.value.trainer2Id) ?? { id: "" };
}


const deletePrice = (c: number) => {
    course.value.coursePrices = course.value.coursePrices.filter((pri, i) => i != c);
}

const addPrice = () => {
    if (!course.value.coursePrices)
        course.value.coursePrices = [];
    course.value.coursePrices.push({ description: {}, price: 0 });
    editPrice(course.value.coursePrices.length - 1);
}

const editPrice = (i: number) => {
    priceEditId.value = i;
}

const templateSale = [
    {
        name: "Koinè",
        value: {
            "DE": "<p>Koinè Konferenzraum 1 - Bozen</p>",
            "IT": "<p>Sala Conferenze Koinè - Bolzano</p>"
        }
    },
    {
        name: "Raiffeisen",
        value: {
            "IT": "<p>Sala Pavillon Raiffeisen</p><p>Via Raiffeisen 2 - 39100 Bolzano</p>",
            "DE": "<p>Raiffeisen Pavillon</p><p>RaiffeisenStr. 2 - 39100 Bozen</p>"
        }
    }
];

const validateLangField = (rule: InternalRuleItem, value: any, callback: (error?: string | Error) => void): SyncValidateResult | void => {
    if (value === null) {
        callback(new Error('Il valore é obbligatorio'))
    }
    const l = value as { [id: string]: string; };
    if (l) {
        if (!l["DE"]) {
            callback(new Error('Il valore é obbligatorio'));
        }
        if (!l["IT"]) {
            callback(new Error('Il valore é obbligatorio'));
        }
    }
    return true;
}

const ruleFormRef = ref<FormInstance>();
const rules = reactive<FormRules<Course>>({
    name: [
        { required: true, message: "Nome non puo essere vuoto", trigger: 'blur' },
    ],
    categoryId: [
        { required: true, message: "Seleziona una categoria", trigger: 'change' },
    ],
    visualizationDate: [
        { required: true, message: "Inserisci una data di visualizzazione", trigger: 'change' },
    ],
    image: [
        { required: false, message: "Imagine necessaria", trigger: 'change' },
    ],
    title: [
        { validator: validateLangField, required: false, message: "Titolo necessario", trigger: 'change' },
    ],
    content: [
        { validator: validateLangField, required: false, message: "Titolo necessario", trigger: 'change' },
    ]
});

const saveGo = ref(false);
const save = async () => {
    await ruleFormRef.value?.validate(async (valid) => {
        if (!valid) {
            ElMessage({
                message: "Ci sono errori nella pagina",
                type: 'error'
            });
            return;
        }
        saveGo.value = true;
        try {
            course.value.tags = tags.value.map((m) => m.text)
            if (!course.value.hasCourseDate) course.value.date = undefined
            if (!course.value.hasCourseTime1) course.value.timeMorning = undefined
            if (!course.value.hasCourseTime2) course.value.timeAfternoon = undefined

            if (course.value.type == CourseType.Master) {
                course.value.trainerId = undefined;
                course.value.trainer2Id = undefined;
            }
            course.value = await courseStore.save(course.value);
            ElMessage({
                message: t('Saved'),
                type: 'success'
            })
            router.push({ name: 'courseList' })
        } catch (err) {
            if (isAxiosError(err) && err.response) {
                ElMessage({
                    message: err.message,
                    type: 'error'
                })
            }
        }
        saveGo.value = false;
    })
}

const delet = async () => {
    await courseStore.deleteCourse(course.value);
    ElMessage({
        message: t('Deleted'),
        type: 'success'
    })
    router.push({ name: 'courseList' })
}
</script>

<template>
    <el-card class="box-card w-100 m-20" v-if="loaded">
        <template #header>
            <div class="card-header">
                <div class="text-2xl left">{{ $t("course.edit.pagetitle") }}</div>
            </div>
        </template>
        <el-form ref="ruleFormRef" :model="course" label-width="180px" label-position="left" :rules="rules">
            <el-form-item :label="$t('course.edit.name')" prop="name">
                <el-input v-model="course.name" :placeholder="t('course.edit.name')" class="m-1" />
            </el-form-item>
            <el-form-item :label="$t('course.edit.coursetype')" prop="coursetype">
                <el-select v-model="course.type" :placeholder="$t('course.edit.coursetype')">
                    <el-option v-for="c in ctypes" :key="c.key" :value="c.key" :label="c.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('course.edit.date')" v-if="course.type != CourseType.Master" prop="date">
                <el-switch v-model="course.hasCourseDate" class="mr-2" />
                <el-date-picker v-model="course.date" type="date" :placeholder="$t('course.edit.date')" format="DD/MM/YYYY"
                    :disabled="!course.hasCourseDate" />
            </el-form-item>
            <el-form-item :label="$t('course.edit.timeMorning')" v-if="course.type != CourseType.Master" prop="timeMorning">
                <el-switch v-model="course.hasCourseTime1" class="mr-2" />
                <div class="w-72">
                    <el-time-picker v-model="course.timeMorning" is-range range-separator="To"
                        start-placeholder="Start time" end-placeholder="End time" format="HH:mm"
                        :disabled="!course.hasCourseTime1" />
                </div>
            </el-form-item>
            <el-form-item :label="$t('course.edit.timeAfternoon')" v-if="course.type != CourseType.Master"
                prop="timeAfternoon">
                <el-switch v-model="course.hasCourseTime2" class="mr-2" />
                <div class="w-72">
                    <el-time-picker v-model="course.timeAfternoon" is-range range-separator="To"
                        start-placeholder="Start time" end-placeholder="End time" format="HH:mm"
                        :disabled="!course.hasCourseTime2" />
                </div>
            </el-form-item>
            <el-form-item :label="$t('course.edit.category')" prop="categoryId">
                <el-select v-model="course.categoryId" multiple class="m-2 self-start" placeholder="Select">
                    <el-option v-for="item in categoryStore.categories" :key="item.id" :label="item.title[$t('key')]"
                        :value="item.id" />
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('course.edit.visualizationDate')" prop="visualizationDate">
                <el-date-picker v-model="course.visualizationDate" type="date"
                    :placeholder="$t('course.edit.visualizationDate')" format="DD/MM/YYYY" />
            </el-form-item>
            <el-form-item :label="$t('course.edit.heroImage')" prop="image">
                <div v-for="hero in heroStore.heros" :key="hero.id" class="m-3 flex justify-center items-center flex-col">
                    <div class="w-32 h-32 bg-contain bg-no-repeat bg-center" @click="course.image = hero.url"
                        :style="'background-image: url(\'' + hero.url + '\')'">
                        <div class="w-32 h-32 flex  justify-center items-center backdrop-brightness-50"
                            v-if="hero.url == course.image">
                            <span class="text-white text-4xl w-1/2 text-center">
                                <font-awesome-icon icon="circle-check" />
                            </span>
                        </div>
                    </div>
                </div>
            </el-form-item>
            <el-form-item :label="$t('course.edit.masterCourses')" v-if="course.type == CourseType.Master"
                prop="masterCourses">
                <el-date-picker v-model="course.masterVisualizationDate" type="date"
                    @change="LoadCoursesForMaster(course.masterVisualizationDate ?? new Date())"
                    placeholder="Minimum Date" format="DD/MM/YYYY" />

                <el-transfer v-model="course.masterCourses" filterable :filter-placeholder="$t('Search')"
                    :data="allTransferCourses" class="w-full"
                    :titles="[$t('course.edit.allCourses'), $t('course.edit.includedCourses')]" :format="{
                        noChecked: '${total}',
                        hasChecked: '${total}',
                    }" />
            </el-form-item>
            <el-form-item :label="$t('course.edit.needsFiscalCode')" prop="needsFiscalCode">
                <el-switch v-model="course.needsFiscalCode" class="ml-2" />
            </el-form-item>
            <el-form-item :label="$t('course.edit.maxPartecipants')" prop="maxPartecipants">
                <el-input-number v-model="course.maxPartecipants" :min="1" :max="1000" />
            </el-form-item>
            <el-form-item :label="$t('course.edit.credits')" prop="credits">
                <el-input v-model="course.credits" />
            </el-form-item>
            <el-form-item :label="$t('course.edit.isOnlineCourse')"
                prop="isOnlineCourse">
                <el-switch v-model="course.isOnlineCourse" class="ml-2" />
            </el-form-item>
            <el-form-item :label="$t('course.edit.address')" prop="address">
                <wysiwygEditor v-model:content="course.address" :templateValues="templateSale">
                </wysiwygEditor>
            </el-form-item>
            <el-form-item :label="$t('course.edit.isVisible')" prop="isVisible">
                <el-switch v-model="course.isVisible" class="ml-2" />
            </el-form-item>
            <el-form-item :label="$t('course.edit.title')" prop="title">
                <el-input v-for="item  in  languages " :key="item" v-model="course.title[item]"
                    :placeholder="t('menuEditor.menu.title')" class="m-1"><template #prepend><span class="w-5">{{ item
                    }}</span></template></el-input>
            </el-form-item>
            <el-form-item :label="$t('course.edit.tags')" prop="tags">
                <vue-tags-input v-model="tag" v-model:tags="tags" :placeholder="$t('course.edit.tags')" />
            </el-form-item>
            <el-form-item :label="$t('course.edit.trainer')" v-if="course.type != CourseType.Master" prop="trainerId">
                <el-select v-model="course.trainerId" clearable class="m-2 self-start" placeholder="Select"
                    @change="changeTrainer">
                    <el-option v-for="item in trainerStore.trainers" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
                <img :src="course.trainer.imageUrl" class="w-32" v-if="course.trainer">
            </el-form-item>
            <el-form-item :label="$t('course.edit.trainer2')" v-if="course.type != CourseType.Master" prop="trainer2Id">
                <el-select v-model="course.trainer2Id" clearable class="m-2 self-start" placeholder="Select"
                    @change="changeTrainer2">
                    <el-option v-for="item in trainerStore.trainers" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
                <img :src="course.trainer2.imageUrl" class="w-32" v-if="course.trainer2">
            </el-form-item>
            <el-form-item :label="$t('course.edit.content')" prop="content">
                <wysiwygEditor v-model:content="course.content"></wysiwygEditor>
            </el-form-item>
            <el-form-item :label="$t('course.edit.price.title')" prop="price">
                <el-table :data="course.coursePrices" stripe style="width: 60%">
                    <el-table-column prop="description" :label="$t('course.edit.price.description')">
                        <template #default="scope">
                            <div v-if="scope.$index == priceEditId">
                                <el-input v-for="item in languages" :key="item" v-model="scope.row.description[item]"
                                    class="m-1"><template #prepend><span class="w-5">{{ item
                                    }}</span></template></el-input>
                            </div>
                            <div v-else>
                                {{ scope.row.description[$t("key")] }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="price" :label="$t('course.edit.price.price')" width="180">
                        <template #default="scope">
                            <el-input-number v-model="scope.row.price" :min="0" :max="10000" :precision="2" />
                        </template></el-table-column>
                    <el-table-column fixed="right" width="120">
                        <template #default="scope">
                            <div v-if="scope.$index != priceEditId">
                                <el-button :icon="Delete" circle @click="deletePrice(scope.$index)"></el-button>
                                <el-button :icon="Edit" circle @click="editPrice(scope.$index)"></el-button>
                            </div>
                            <div v-else>
                                <el-button :icon="Check" circle @click="editPrice(-1)"></el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-button :icon="Plus" @click="addPrice" class="self-start">Add Price</el-button>
            </el-form-item>
            <el-collapse class="m-5">
                <el-collapse-item :title="t('course.edit.extras')" name="1">
                    <el-form-item :label="$t('course.edit.footer')">
                        <wysiwygEditor v-model:content="course.footerText">
                        </wysiwygEditor>
                    </el-form-item>
                    <el-form-item :label="$t('course.edit.annotations')">
                        <wysiwygEditor v-model:content="course.annotations">
                        </wysiwygEditor>
                    </el-form-item>
                    <el-form-item :label="$t('course.edit.textForSubscribers')">
                        <wysiwygEditor v-model:content="course.textForSubscribers">
                        </wysiwygEditor>
                    </el-form-item>
                </el-collapse-item>
            </el-collapse>
            <div class="flex justify-end">
                <el-button class="mr-auto" type="danger" @click="delet">{{ $t("course.edit.delete") }}</el-button>
                <el-button type="primary" @click="save" :disabled="saveGo">{{ $t("course.edit.save") }}</el-button>
            </div>
        </el-form>
    </el-card>
</template>

<style>
.card-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.left {
    display: flex;
    margin-right: auto;
}

.el-form--label-left .el-form-item__label {
    line-height: 16px;
    padding-top: 8px;
}

.el-transfer-panel {
    width: 45% !important;
}

.el-transfer__buttons {
    width: 10% !important;
}
</style>