

import { ref } from 'vue'
import { defineStore } from 'pinia'
import config from '@/config/config';
import axios from 'axios';
import type { Trainer } from '@/models/trainer';

export const useTrainerStore = defineStore('Trainer', () => {

  const trainers = ref([] as Trainer[])

  const load = async () =>{ 
    const res = await axios.get<Trainer[]>(config().BASEURL + config().TEACHER);
    trainers.value = res.data;
  }

  const save = async (trainer: Trainer) =>{ 
    const res = await axios.post<Trainer>(config().BASEURL + config().TEACHER, trainer, { headers: { Authorization: 'Bearer ' + localStorage.getItem("loginToken") as string } });
    
    load();
    return res.data;
  }

  const deleteTeacher = async (trainer: Trainer) =>{ 
    const res = await axios.delete<boolean>(config().BASEURL + config().TEACHER + "/" + trainer.id, { headers: { Authorization: 'Bearer ' + localStorage.getItem("loginToken") as string } });
    
    load();
    return res.data;
  }

  return { load, trainers, save, deleteTeacher }
})
