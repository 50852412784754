import Quill from 'quill';

const Link = Quill.import('formats/link');


export default class Attachment extends Link {

  static create(value : {
    id: string,
    properties: { dataUrl: string | ArrayBuffer | null, file: File },
    render(node:HTMLElement, filetype: {file: File}): HTMLElement
  }) {
    const { properties, id, render } = value
    const node = super.create(value);
    if(!render) // We have a link, probably not a attachment
       return node;

    node.setAttribute('id', id);
    node.setAttribute('rel', 'noopener noreferrer');
    node.setAttribute('target', '_blank');
    node.setAttribute('href', null);
    const n = render(node, properties);
    return n;
  }
}

Attachment.blotName  = 'attachment';
Attachment.tagName   = 'a';