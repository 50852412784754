<script setup lang="ts">
import { useCourseCategoryStore } from '@/stores/categoryStore';
import { useRouter } from 'vue-router';
import type { CourseCategory } from '@/models/courseCategory';
import { onMounted} from 'vue';

const router = useRouter();
const catStore = useCourseCategoryStore();

const edit = (row: CourseCategory) => {
    console.log(row);
    router.push({ name: 'categoryEdit', params: { 'Id': row.id } });
}

onMounted(() => {
    catStore.load();
});

</script>

<template>
    <el-card class="box-card w-100 m-20">
        <template #header>
            <div class="card-header">
                <div class="text-2xl left">{{ $t("category.list") }}</div>
                <el-button class="button" type="primary" @click="router.push({ name: 'categoryEdit', params: { 'Id': -1 } })">{{
                    $t("category.new")
                }}</el-button>
            </div>
        </template>
        <el-table :data="catStore.categories" style="width: 100%" @row-click="edit" default-expand-all row-key="id">
            <!--<el-table-column prop="id" label="Id" width="180" />-->
            <el-table-column prop="title">
                <template #default="scope">
                    <div v-html="scope.row.title[$t('key')]" />
                </template>
            </el-table-column>
        </el-table>
    </el-card>
</template>