export interface AdminUser {
  email: string
  name: string
  roles: UserRoles[]
  password: string
  loading: boolean
}

export enum UserRoles
{
    None,
    Admin
}
