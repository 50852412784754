<template>
    <el-card class="box-card w-100 m-20">
        <template #header>
            <div class="card-header">
                <div class="text-2xl left">{{ $t("users.title") }}</div>
                <div class="w-64 m-2">
                    <el-input v-model="searchText" :placeholder="$t('users.search')" clearable
                        :prefix-icon="Search" />
                </div>
            </div>
        </template>
        <el-table :data="data" style="width: 100%" default-expand-all row-key="id">
            <el-table-column prop="email" :label="$t('users.table.email')" />
            <el-table-column prop="name" :label="$t('users.table.name')" />
            <el-table-column prop="roles" :label="$t('users.table.roles')">
                <template #default="scope">
                    <div v-for="r in scope.row.roles" v-bind:key="r">{{  UserRoles[r] }}</div>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="Operations" width="160">
                <template #default="scope">
                    <el-button link :loading="scope.row.loading" type="primary" @click="sendReset(scope.row)">{{
                        $t("users.table.resetPwd")
                    }}</el-button>
                    <el-button link :loading="scope.row.loading" type="danger" @click="deleteRow(scope.row)" :icon="Delete"></el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-button v-if="hasHext" class="button m-3" @click="next()">{{
            $t("users.next") }}</el-button>
    </el-card>
</template>
    
<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import config from '@/config/config'
import axios from 'axios';
import { UserRoles, type AdminUser } from '@/models/adminUser';
import { ElMessage, ElMessageBox } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { useLoginStore } from '@/stores/loginStore';
import { Delete, Search } from '@element-plus/icons-vue';

const data = ref([] as AdminUser[]);
const { t } = useI18n();
const loginStore = useLoginStore();
const hasHext = ref(false);
const page = ref(0);
const searchText = ref("")

onMounted(async () => {
    getUsers();
});

watch(searchText, () => {
    page.value = 0;
    data.value = [];
    getUsers(searchText.value);
});

const next = () => {
    page.value = page.value + 1;
    getUsers(undefined, page.value);
}
const getUsers = async (search?: string, page?: number) => {
    const g = await axios.get<AdminUser[]>(config().BASEURL + config().ADMINUSERSURL, { 
        headers: { Authorization: 'Bearer ' + localStorage.getItem("loginToken") },
        params: {
            search,
            size: 50,
            page
        }
    });
    hasHext.value = g.data.length == 50;
    data.value = data.value.concat(g.data);
}
const sendReset = async (row: AdminUser) => {
    row.loading = true;
    try {
        await loginStore.sendPwdReset(row.email, t('key'));
        row.loading = false;
        ElMessage({
            message: 'Password sent successfully.',
            type: 'success',
        });
    } catch (e) {
        row.loading = false;
        ElMessage({
            message: 'Password could not be sent: ' + e,
            type: 'error',
        });
    }
};
const deleteRow = async (row: AdminUser) => {
const ret = await ElMessageBox.confirm(
    'Sicura che vuoi cancellare l\'utente?',
    'Warning',
    {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning',
    }
  );
  if (ret === 'confirm') {
    await axios.delete(config().BASEURL + config().ADMINUSERURL + row.email, { headers: { Authorization: 'Bearer ' + localStorage.getItem("loginToken") } });
    row.loading = false;
    ElMessage({
        message: 'User deleted',
        type: 'success',
    });
    const g2 = await axios.get<AdminUser[]>(config().BASEURL + config().ADMINUSERSURL, { headers: { Authorization: 'Bearer ' + localStorage.getItem("loginToken") } });
    data.value = g2.data;
  }
}
</script>
