<script setup lang="ts">
import { useTrainerStore } from '@/stores/teacherStore';
import { useRouter } from 'vue-router';
import type {Trainer} from '@/models/trainer';
import { onMounted } from 'vue';

const router = useRouter();
const trainerStore = useTrainerStore();

const edit = (row: Trainer) => {
    router.push({name: 'trainerEdit', params:{'Id': row.id}});
}

onMounted(() => {
    trainerStore.load();
})

</script>

<template>
    <el-card class="box-card w-100 m-20">
        <template #header>
            <div class="card-header">
                <div class="text-2xl left">{{ $t("trainer.list") }}</div>
                <el-button class="button" @click="router.push({ name: 'trainerEdit', params: { 'Id': -1 } })">{{ $t("trainer.new")
                }}</el-button>
            </div>
        </template>
        <el-table :data="trainerStore.trainers" style="width: 100%" @row-click="edit" default-expand-all row-key="id">
            <!--<el-table-column prop="id" label="Id" width="180" />-->
            <el-table-column min-width="55" prop="imageUrl" label="" width="150">
                <template #default="scope">
                    <img :src="scope.row.imageUrl" class="w-128" />
                </template>
            </el-table-column> <el-table-column prop="name" label="Name" />
        </el-table>
    </el-card>
</template>