<script setup lang="ts">
import config from '@/config/config';
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useTrainerStore } from '@/stores/teacherStore';
import type { Trainer } from '@/models/trainer';
import { Plus } from '@element-plus/icons-vue';
import { ElMessage } from 'element-plus';
import type { UploadProps } from 'element-plus';

let newTrainer = {
    id: "",
    description: {},
    imageUrl: "",
    name: ""
} as Trainer;

const store = useTrainerStore();
const { t } = useI18n();

const route = useRoute();
const router = useRouter();
const loaded = ref(false);

const trainer = ref(newTrainer);

const save = () => {
    store.save(trainer.value);
    router.push({ name: 'trainerList' });
};

const deleteTeacher = () => {
    store.deleteTeacher(trainer.value);
    router.push({ name: 'trainerList' });
};

const handleAvatarSuccess: UploadProps['onSuccess'] = (response) => {
    trainer.value.imageUrl = response.url;
}

const beforeAvatarUpload: UploadProps['beforeUpload'] = (rawFile) => {
    if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png') {
        ElMessage.error('Avatar picture must be JPG or PNG format!')
        return false;
    } else if (rawFile.size / 1024 / 1024 > 2) {
        ElMessage.error('Avatar picture size can not exceed 2MB!')
        return false;
    }
    return true;
}

onMounted(async () => {
    if (route.params.Id && route.params.Id != "-1") {
        if (store.trainers.length == 0)
            await store.load();
        const x = store.trainers.find(f => f.id == route.params.Id)
        if (x)
            trainer.value = x;
    }
    loaded.value = true;
});
const headers = { Authorization: 'Bearer ' + localStorage.getItem("loginToken") as string };
</script>

<template>
    <el-card class="box-card w-100 m-20" v-if="loaded">
        <template #header>
            <div class="card-header">
                <div class="text-2xl left">{{ $t("trainer.edit.pagetitle") }}</div>
            </div>
        </template>
        <el-form :model="trainer" label-width="180px" label-position="left">
            <el-form-item :label="$t('course.edit.name')">
                <el-input v-model="trainer.name" :placeholder="t('trainer.edit.name')" class="m-1" />
            </el-form-item>
            <el-form-item :label="$t('trainer.edit.description')">
                <wysiwygEditor v-model:content="trainer.description"></wysiwygEditor>
            </el-form-item>
            <el-upload class="avatar-uploader" :action="config().BASEURL + config().UPLOAD" :headers="headers"
                :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="trainer.imageUrl" :src="trainer.imageUrl" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon">
                    <Plus />
                </el-icon>
            </el-upload>
            <div class="flex justify-end">
                <el-button type="primary" @click="save">{{ $t("trainer.edit.save") }}</el-button>
                <el-button type="danger" @click="deleteTeacher">{{ $t("trainer.edit.delete") }}</el-button>
            </div>
        </el-form>
    </el-card>
</template>

<style scoped>
.avatar-uploader .avatar {
    min-width: 178px;
    height: 178px;
    display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

.card-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.left {
    display: flex;
    margin-right: auto;
}

.el-form--label-left .el-form-item__label {
    line-height: 16px;
    padding-top: 8px;
}
</style>