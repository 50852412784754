<script setup lang="ts">
import axios from 'axios';
import config from '@/config/config';
import { ref, onMounted } from 'vue';
import type { Menu } from '@/models/menu';
import { useRoute } from 'vue-router'
import { LanguageEnum } from '@/models/languages';
import { Plus, Delete, ArrowUp, ArrowDown } from '@element-plus/icons-vue'
import { ElNotification } from 'element-plus';
import { useI18n } from 'vue-i18n';
import { Destinations } from '@/models/destinationsEnum';
import { useCourseStore } from '@/stores/courseStore';
import { useCourseCategoryStore } from '@/stores/categoryStore';
import type { UploadProps } from 'element-plus';
import { ElMessage } from 'element-plus';

const { t } = useI18n();
const courseStore = useCourseStore();
const categoryStore = useCourseCategoryStore();
const menu = ref({} as Menu);
const menuEdit = ref({} as Menu);
const actualMenu = ref("");
const dialogVisible = ref(false);
const router = useRoute();
const languages = Object.keys(LanguageEnum).filter(k => isNaN(Number(k)));
let publicUrlsChanged: { [id: string]: boolean; } = {};

const emptyMenu = {
    description: {},
    destination: {
        type: Destinations.Content,
        link: ""
    },
    id: "",
    publicUrl: {},
    show: true,
    subMenu: [],
    title: {},
} as Menu;

const getMenu = async (m: string) => {
    try {
        const res = await axios.get<Menu>(config().BASEURL + config().MENU + m, { headers: { Authorization: 'Bearer ' + localStorage.getItem("loginToken") as string } });
        const mdata = res.data;
        menu.value = mdata;
    } catch {
        menu.value = JSON.parse(JSON.stringify(emptyMenu));
        menu.value.id = m;
    }
}

const saveMenu = async () => {
    const res = await axios.post<Menu>(config().BASEURL + config().MENU + actualMenu.value, menu.value, { headers: { Authorization: 'Bearer ' + localStorage.getItem("loginToken") as string } });
    menu.value = res.data;

    ElNotification({
        title: t('Saved'),
        type: 'success'
    });
}

onMounted(async () => {
    actualMenu.value = router.params.menu as string;
    await getMenu(actualMenu.value);
    await courseStore.getCourses(true);
    await categoryStore.load();
});

const newSubMenu = (menu: Menu) => {
    const me = JSON.parse(JSON.stringify(emptyMenu));
    languages.forEach(l => me.title[l] = "New");
    menu.subMenu.push(me);
}
const delSubMenu = (menu: Menu, submenu: Menu) => {
    menu.subMenu = menu.subMenu.filter(f => f != submenu);
}
const up = (menu: Menu, index: number) => {
    if (menu.subMenu.length > 1) {
        var x = menu.subMenu[index - 1];
        menu.subMenu[index - 1] = menu.subMenu[index];
        menu.subMenu[index] = x;
    }
}
const down = (menu: Menu, index: number) => {
    console.log("up1", JSON.stringify(menu));
    if (menu.subMenu.length > index + 1) {
        var x = menu.subMenu[index + 1];
        menu.subMenu[index + 1] = menu.subMenu[index];
        menu.subMenu[index] = x;
    }
    console.log("up2", JSON.stringify(menu));
}

const titelChange = (lang: string) => {
    if (!publicUrlsChanged[lang])
        menuEdit.value.publicUrl[lang] = menuEdit.value.title[lang].replace(/ /g, "-");
}

const publicUrlChange = (lang: string) => {
    menuEdit.value.publicUrl[lang] = menuEdit.value.publicUrl[lang].replace(/ /g, "-");
    publicUrlsChanged[lang] = true;
}

const activateDialog = (men: Menu) => {
    dialogVisible.value = true;
    menuEdit.value = men;
    publicUrlsChanged = {};
}
const handleAvatarSuccess: UploadProps['onSuccess'] = (response) => {
    menuEdit.value.imageUrl = response.url;
}
const handleRemove = () => {
    menuEdit.value.imageUrl = undefined;
}

const beforeAvatarUpload: UploadProps['beforeUpload'] = (rawFile) => {
    if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png') {
        ElMessage.error('Avatar picture must be JPG or PNG format!')
        return false;
    } else if (rawFile.size / 1024 / 1024 > 2) {
        ElMessage.error('Avatar picture size can not exceed 2MB!')
        return false;
    }
    return true;
}
const headers = { Authorization: 'Bearer ' + localStorage.getItem("loginToken") as string };
</script>

<template>
    <el-card class="box-card w-100 m-20">
        <template #header>
            <div class="card-header">
                <div class="text-2xl">Menu {{ actualMenu }}</div>
                <el-button type="primary" text @click="newSubMenu(menu)">{{ $t("menuEditor.addSubmenu") }}</el-button>
            </div>
        </template>
        <div v-for="(men, idx) in menu.subMenu" :key="men.id" class="my-10">
            <el-row>
                <el-col :span="10"><el-button text @click="activateDialog(men)">{{ men.title[t("key")]
                }}</el-button></el-col><el-col :span="12"><el-button type="primary" text :icon="Plus"
                        @click="newSubMenu(men)"></el-button><el-button type="danger" text :icon="Delete"
                        @click="delSubMenu(menu, men)"></el-button><el-button v-if="idx != 0" text :icon="ArrowUp"
                        @click="up(menu, idx)"></el-button><el-button v-if="idx != (menu.subMenu.length - 1)" text
                        :icon="ArrowDown" @click="down(menu, idx)"></el-button></el-col>
            </el-row>
            <div v-for="(me, idx2) in men.subMenu" :key="me.id" class="my-2">
                <el-row>
                    <el-col :span="10" class="pl-5"><el-button text @click="activateDialog(me)">{{ me.title[t("key")]
                    }}</el-button></el-col><el-col :span="10" class="pl-5"><el-button type="danger" text
                            :icon="Delete" @click="delSubMenu(men, me)"></el-button><el-button v-if="idx2 != 0" text
                            :icon="ArrowUp" @click="up(men, idx2)"></el-button><el-button
                            v-if="idx2 != (men.subMenu.length - 1)" text :icon="ArrowDown"
                            @click="down(men, idx2)"></el-button></el-col>
                </el-row>
            </div>
        </div>
        <div class="flex justify-end">
            <el-button type="primary" @click="saveMenu">{{ $t("menuEditor.save") }}</el-button>
        </div>
    </el-card>


    <el-dialog v-model="dialogVisible" title="Menu">
        <div v-if="menuEdit.title">
            <div class="m-5">
                <span class="ml-3 inline-flex">{{ $t('menuEditor.menu.title') }}</span>
                <el-input v-for="item in languages" :key="item" v-model="menuEdit.title[item]"
                    :placeholder="t('menuEditor.menu.title')" class="m-1" @change="titelChange(item)"><template
                        #prepend><span class="w-5">{{ item }}</span></template></el-input>
            </div>
            <div class="m-5">
                <div class="ml-3">{{ $t('menuEditor.menu.destination') }}</div>
                <el-select v-model="menuEdit.destination.type">
                    <el-option v-for="opt in Object.values(Destinations).filter((v) => !isNaN(Number(v)))" v-bind:key="opt"
                        :label="t('menuEditor.' + Destinations[opt as number])" :value="opt" />
                </el-select>
                <el-input v-if="menuEdit.destination.type == Destinations.Content" v-model="menuEdit.destination.link"
                    :placeholder="t('menuEditor.destinationLinkContent')"></el-input>
                <el-select v-if="menuEdit.destination.type == Destinations.Courses" v-model="menuEdit.destination.link"
                    :placeholder="$t('menuEditor.destinationLinkcourse')" class="block w-full">
                    <el-option v-for="c in courseStore.courses" :key="c.id" :value="c.id" :label="c.name"></el-option>
                </el-select>
                <el-select v-if="menuEdit.destination.type == Destinations.CourseCategory"
                    v-model="menuEdit.destination.link" class="block w-full" placeholder="Select">
                    <el-option v-for="item in categoryStore.categories" :key="item.id" :label="item.title[$t('key')]"
                        :value="item.id" />
                </el-select>
            </div>
            <el-upload class="avatar-uploader" :action="config().BASEURL + config().UPLOAD" :headers="headers"
                :show-file-list="false" :on-success="handleAvatarSuccess" :on-remove="handleRemove"
                :before-upload="beforeAvatarUpload">
                <img v-if="menuEdit.imageUrl" :src="menuEdit.imageUrl" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon">
                    <Plus />
                </el-icon>
            </el-upload>
            <el-button @click="handleRemove()" v-if="menuEdit.imageUrl"><font-awesome-icon icon="trash" /></el-button>
            <el-collapse class="m-5">
                <el-collapse-item :title="t('menuEditor.menu.description')" name="1">
                    <wysiwygEditor v-model:content="menuEdit.description">
                    </wysiwygEditor>
                </el-collapse-item>
            </el-collapse>
            <!--
            <el-collapse class="m-5">
                <el-collapse-item :title="t('menuEditor.menu.publicUrl')" name="1">
                    <el-input v-for="item in languages" :key="item" v-model="menuEdit.publicUrl[item]"
                        :placeholder="t('menuEditor.menu.publicUrl')" class="m-1" @change="publicUrlChange(item)"><template
                            #prepend><span class="w-5">{{ item }}</span></template></el-input>
                </el-collapse-item>
            </el-collapse>
            -->
            <div class="m-5">
                <span class="ml-3 inline-flex">{{ $t('menuEditor.menu.loginMandatory') }}</span>
                <el-switch class="ml-5" v-model="menuEdit.loginMandatory" />
            </div>
            <div class="m-5">
                <span class="ml-3 inline-flex">{{ $t('menuEditor.menu.show') }}</span>
                <el-switch class="ml-5" v-model="menuEdit.show" />
            </div>
        </div>
    </el-dialog>
</template>

<style>
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}</style>